import { Divider, useAuthenticator } from "@aws-amplify/ui-react";
import { Icon } from "@iconify/react";
import { Buffer } from "buffer";
import { useFormik } from "formik";
import isEqual from "lodash.isequal";
import { BlockUI } from "primereact/blockui";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { TabMenu } from "primereact/tabmenu";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import UserService from "../../../../services/UserService/UserService";
// import { capitalizeWord, getUpdateRemoveParams } from "../../../Utils";
import getSymbolFromCurrency from "currency-symbol-map";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { FormatCurrency, capitalizeWord, convertToBase64, getAuthData, getUpdateRemoveParams, updateObjectProperty } from "../../../../Utils";
import dropdownDown from "../../../../assets/icons/dropdown-down.svg";
import AvatarUpload from "../../../../components/AvatarUpload/AvatarUpload";
import { countryOptionTemplate, getCountriesTranslated, getCountryByCode, selectedCountryTemplate } from "../../../../data/General";
import { checkComponentPermissions } from "../../../../data/Permissions";
import { getUserStatusChip } from "../../../../data/User";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt";
import AbsenceService from "../../../../services/AbsenceService/AbsenceService";
import ProfileService from "../../../../services/ProfileService/ProfileService";
import TenantService from "../../../../services/TenantService/TenantService";
import Forbidden from "../../../Auth/Forbidden";
import { filterProfiles, handleProfilesOnScroll } from "../../Profiles/Utils";
import { containsProfile, getUserLogoUrl } from "../Utils";
import UserAbsences from "./UserAbsences";
import "./UserOverview.scss";
import UserSecurity from "./UserSecurity";
import UserTrack from "./UserTrack";

const UserOverview = () => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const toast = useRef(null);

    const fileUploadRef = useRef(null);
    const [currentFile, setCurrentFile] = useState();

    const [loading, setLoading] = useState(false);
    const [makingMinorChanges, setMakingMinorChanges] = useState(undefined);

    const [activeIndex, setActiveIndex] = useState(0);

    const userService = new UserService();
    const tenantService = new TenantService();
    const profileService = new ProfileService();
    const absenceService = new AbsenceService();

    const [changesDetected, setChangesDetected] = useState(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(changesDetected);

    const [showCreateTemplateDialog, setShowCreateTemplateDialog] = useState(false);
    const [originalUser, setOriginalUser] = useState();
    const [totalCostExpandedRows, setTotalCostExpandedRows] = useState([]);

    const { user } = useAuthenticator((context) => [context.user]);
    const [tenantInfo, setTenantInfo] = useState();

    // Absence Hours
    const [absenceHours, setAbsenceHours] = useState([]);
    const [queryAbsenceHoursResponse, setQueryAbsenceHoursResponse] = useState();

    // Working Hours
    const [workingHours, setWorkingHours] = useState([]);
    const [queryWorkingHoursResponse, setQueryWorkingHoursResponse] = useState();

    // Absences
    const [absences, setAbsences] = useState([]);
    const [queryAbsencesResponse, setQueryAbsencesResponse] = useState();

    // Profiles
    const [profiles, setProfiles] = useState([]);
    const [queryProfilesResponse, setQueryProfilesResponse] = useState();
    const [profilesRowsNumber, setProfilesRowsNumber] = useState(10);
    const [profileFilterName, setProfileFilterName] = useState(undefined);
    const [loadingProfiles, setLoadingProfiles] = useState(false);

    // User Profiles
    const [userProfiles, setUserProfiles] = useState();

    const [assigningProfiles, setAssigningProfiles] = useState([]);
    const [unassigningProfiles, setUnassigningProfiles] = useState([]);

    // User Roles
    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        setLoading(true);

        if (searchParams.get("view")) {
            if (searchParams.get("view") === "track") {
                setActiveIndex(1);
            } else if (searchParams.get("view") === "absences") {
                setActiveIndex(2);
            } else if (searchParams.get("view") === "salary_details") {
                setActiveIndex(3);
            } /*else if (searchParams.get("view") === "social_links") {
                setActiveIndex(4);
            } */ /* else if (searchParams.get("view") === "files") {
                setActiveIndex(5);
            } */ else if (searchParams.get("view") === "skills") {
                setActiveIndex(4);
            } else if (searchParams.get("view") === "security") {
                setActiveIndex(5);
            } else {
                searchParams.set("view", "general_information");
                setSearchParams(searchParams);
                setActiveIndex(0);
            }
        } else {
            searchParams.set("view", "general_information");
            setSearchParams(searchParams);
            setActiveIndex(0);
        }
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            let calls = [];
            calls.push(
                tenantService
                    .getTenant(user.attributes["custom:tenant_id"])
                    .then((data) => {
                        return data;
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    })
            );
            calls.push(
                absenceService.queryAbsences({ limit: 200 }).then((data) => {
                    return data;
                })
            );
            calls.push(
                profileService
                    .queryProfiles({ limit: 200, user_id: id }, undefined, "private")
                    .then((data) => {
                        return data;
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    })
            );
            calls.push(
                userService.getUser({}, id).then((data) => {
                    return data;
                })
            );

            Promise.all(calls).then(([tenantInfo, absencesData, profilesData, userInfo]) => {
                if (tenantInfo) {
                    setTenantInfo(tenantInfo);
                }
                if (absencesData) {
                    setQueryAbsencesResponse(absencesData);
                    setAbsences(absencesData?.data);
                }
                if (profilesData) {
                    setUserProfiles(profilesData?.data);
                }
                if (userInfo) {
                    formik.setValues(userInfo);
                    setOriginalUser(JSON.parse(JSON.stringify(userInfo)));
                } else {
                    navigate("/notfound");
                }
                setLoading(false);
            });
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            name: "",
            surnames: "",
            type: "employee",
            hour_cost: 0,
            email: "",
            external: false,
            status: "draft",
            locale: i18n?.language,
            address: {
                city: undefined,
                country: undefined,
                line1: undefined,
                line2: undefined,
                postal_code: undefined,
                state: undefined,
            }
        },
        validate: (data) => {
            let errors = {};

            if (activeIndex === 0) {
                if (!data.name) {
                    errors.name = t("label.field_required");
                }

                if (!data.surnames) {
                    errors.surnames = t("label.field_required");
                }

                if (!data.email) {
                    errors.email = t("label.field_required");
                }
            } else if (activeIndex === 5) {
                if (!data.old_password) {
                    errors.old_password = t("label.field_required");
                }
                if (!data.new_password) {
                    errors.new_password = t("label.field_required");
                }
                if (!data.confirm_password) {
                    errors.confirm_password = t("label.field_required");
                } else if (data.new_password && data.confirm_password && data.new_password !== data.confirm_password) {
                    errors.confirm_password = t("label.passwords_must_match");
                }
            }

            // if (!data.hour_cost) {
            //     errors.hour_cost = t("label.field_required");
            // }

            // if (!data.roles || data?.roles?.length === 0) {
            //     errors.roles = t("label.field_required");
            // }

            return errors;
        },
        onSubmit: (data) => {
            // if (activeIndex === 0) {
            setLoading(true);
            const updateData = getUpdateRemoveParams(originalUser, data);

            userService
                .updateUser({}, updateData, id)
                .then((data) => {
                    if (originalUser && data.avatar && data.avatar !== originalUser.avatar && currentFile) {
                        convertToBase64(currentFile).then((convertedFile) => {
                            fetch(data.avatar, {
                                method: "PUT",
                                body: new Buffer.from(convertedFile.replace(/^data:image\/\w+;base64,/, ""), "base64"),
                                headers: {
                                    "Content-type": currentFile.type,
                                },
                            })
                                .then((response) => {
                                    formik.setValues(data);
                                    setOriginalUser(JSON.parse(JSON.stringify(data)));
                                    setLoading(false);
                                    setChangesDetected(false);
                                })
                                .catch((error) => {
                                    console.error("Error:", error);
                                    formik.resetForm();
                                    setLoading(false);
                                });
                        });
                    } else {
                        formik.setValues(data);
                        setOriginalUser(JSON.parse(JSON.stringify(data)));
                        setLoading(false);
                        setChangesDetected(false);
                    }

                    // formik.setValues(data);
                    // setOriginalUser(JSON.parse(JSON.stringify(data)));
                    // setChangesDetected(false);
                    // setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data, life: 5000 });
                });
            // }
        },
    });

    const updateUserField = (fieldName, fieldValue) => {
        let newData = formik.values;
        // newData[fieldName] = fieldValue;
        updateObjectProperty(newData, fieldName, fieldValue);

        if (!isEqual(newData, originalUser)) {
            formik.setFieldValue(fieldName, fieldValue);
        }

        setChangesDetected(!isEqual(newData, originalUser));
    };

    const getTabItems = () => {
        let tabItems = [
            {
                icon: <Icon icon="solar:user-id-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />,
                label: t("label.general_information"),
                command: () => {
                    searchParams.set("view", "general_information");
                    setSearchParams(searchParams);
                    setActiveIndex(0);
                },
            },
            {
                icon: <Icon icon="solar:alarm-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />,
                label: t("label.track"),
                command: () => {
                    searchParams.set("view", "track");
                    setSearchParams(searchParams);
                    setActiveIndex(1);
                },
            },
            {
                icon: <Icon icon="solar:calendar-search-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />,
                label: t("label.absences"),
                command: () => {
                    searchParams.set("view", "absences");
                    setSearchParams(searchParams);
                    setActiveIndex(2);
                },
            },
            {
                icon: <Icon icon="solar:money-bag-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />,
                label: t("label.salary_details"),
                command: () => {
                    searchParams.set("view", "salary_details");
                    setSearchParams(searchParams);
                    setActiveIndex(3);
                },
            },
            // {
            //     icon: <Icon icon="solar:share-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />,
            //     label: t("label.social_links"),
            //     command: () => {
            //         searchParams.set("view", "social_links");
            //         setSearchParams(searchParams);
            //         setActiveIndex(4);
            //     },
            // },
            {
                icon: <Icon icon="solar:ferris-wheel-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />,
                label: t("label.skills"),
                command: () => {
                    searchParams.set("view", "skills");
                    setSearchParams(searchParams);
                    setActiveIndex(4);
                },
            },
            // {
            //     icon: <Icon icon="solar:cloud-file-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />,
            //     label: t("label.files"),
            //     command: () => {
            //         searchParams.set("view", "files");
            //         setSearchParams(searchParams);
            //         setActiveIndex(5);
            //     },
            // },
            {
                icon: <Icon icon="solar:lock-keyhole-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />,
                label: t("label.security"),
                command: () => {
                    searchParams.set("view", "security");
                    setSearchParams(searchParams);
                    setActiveIndex(5);
                },
            },
        ];

        // if (getAuthData()?.user_id === id) {
        // tabItems.push({
        //     icon: <Icon icon="solar:lock-keyhole-bold-duotone" style={{ fontSize: "20px" }} className="mr-3" />,
        //     label: t("label.security"),
        //     command: () => {
        //         searchParams.set("view", "security");
        //         setSearchParams(searchParams);
        //         setActiveIndex(5);
        //     },
        // });
        // }

        return tabItems;
    };

    const deleteUser = () => {
        if (originalUser?.status !== "inactive" && originalUser?.status !== "draft") {
            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: t("users.page.to_delete_a_user_the_user_status_must_be_inactive"), life: 5000 });
        } else {
            setMakingMinorChanges("removing_user");

            const userFullname = `${originalUser?.name ? originalUser?.name : ""} ${originalUser?.surnames ? originalUser?.surnames : ""}`.trim();
            confirmDialog({
                message: <span>{t("users.page.are_you_sure_you_want_to_delete_the_user", { username: userFullname })}</span>,
                header: t("label.delete_user"),
                draggable: false,
                acceptLabel: t("label.delete_user"),
                icon: "pi pi-info-circle",
                acceptClassName: "p-button-danger",
                accept: () => {
                    setLoading(true);
                    userService
                        .deleteUser({}, originalUser?.id)
                        .then((response) => {
                            profileService
                                .queryProfiles({ user_id: originalUser?.id, limit: 50 }, undefined, "private")
                                .then((data) => {
                                    if (data && data?.data && data?.data?.length > 0) {
                                        const profilesToRemove = {
                                            remove: data.data.map((newProfile) => newProfile.id),
                                        };
                                        let calls = [];

                                        calls.push(profileService.asignUser(profilesToRemove, originalUser?.id));
                                        calls.push(userService.asignProfiles(profilesToRemove, originalUser?.id));
                                        Promise.all(calls).then(() => {
                                            setMakingMinorChanges(undefined);
                                        });
                                    } else {
                                        setMakingMinorChanges(undefined);
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                });
                        })
                        .catch((error) => {
                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data, life: 5000 });
                            setMakingMinorChanges(undefined);
                        }).finally(() => {
                        setLoading(false);
                        navigate("/people")
                    });
                },
                contentClassName: "pt-3",
            });
        }
    };

    const socialLinksContent = () => {
        return (
            //<div className="grid">
            //<div className="col-12 xl:col-5">
            <div className="grid card">
                <div className="col-12">
                    <label className="mousee-text font-medium font-weight-bold">{t("label.social_links")}</label>
                </div>
                <div className="col-12">
                    <span className="p-input-icon-left min-w-full">
                        <i className="pi">
                            <Icon icon="devicon:linkedin" />
                        </i>
                        <InputText
                            placeholder={t("label.linkedin")}
                            value={formik.values?.social_links?.linkedin}
                            className="min-w-full"
                            onChange={(e) => {
                                updateUserField("social_links.linkedin", e.target.value);
                            }}
                        />
                    </span>
                </div>

                <div className="col-12">
                    <span className="p-input-icon-left min-w-full">
                        <i className="pi">
                            <Icon icon="skill-icons:instagram" />
                        </i>
                        <InputText
                            placeholder={t("label.instagram")}
                            value={formik.values?.social_links?.instagram}
                            className="min-w-full"
                            onChange={(e) => {
                                updateUserField("social_links.instagram", e.target.value);
                            }}
                        />
                    </span>
                </div>

                <div className="col-12">
                    <span className="p-input-icon-left min-w-full">
                        <i className="pi">
                            <Icon icon="logos:facebook" />
                        </i>
                        <InputText
                            placeholder={t("label.meta_or_facebook")}
                            value={formik.values?.social_links?.meta}
                            className="min-w-full"
                            onChange={(e) => {
                                updateUserField("social_links.meta", e.target.value);
                            }}
                        />
                    </span>
                </div>

                <div className="col-12">
                    <span className="p-input-icon-left min-w-full">
                        <i className="pi">
                            <Icon icon="devicon:twitter" />
                        </i>
                        <InputText
                            placeholder={t("label.x_or_twitter")}
                            value={formik.values?.social_links?.x}
                            className="min-w-full"
                            onChange={(e) => {
                                updateUserField("social_links.x", e.target.value);
                            }}
                        />
                    </span>
                </div>
            </div>
            //</div>
            //</div>
        );
    };

    const userInfoContent = () => {
        return (
            <div className="grid">
                <div className="col-12 xl:col-5">
                    <div className="grid card">
                        <div className="col-12">
                            <div className="flex justify-content-end flex-wrap">
                                <div className="flex align-items-center justify-content-end">{getUserStatusChip(formik.values?.status, t)}</div>
                            </div>
                        </div>
                        <div className="col-12">
                            <AvatarUpload
                                inputFileRef={fileUploadRef}
                                file={currentFile ? currentFile : formik.values.avatar}
                                setFile={(newValue) => {
                                    setCurrentFile(newValue);
                                    updateUserField("avatar", getUserLogoUrl(user, originalUser?.id, newValue?.name));
                                    // formik.setTouched({}, false);
                                }}
                                classnames={classNames({ "p-invalid": isFormFieldValid("image") })}
                            />
                        </div>
                        {formik.values.status === "draft" ? (
                            <div className="col-12">
                                <div className="flex justify-content-end flex-wrap">
                                    <div className="flex align-items-center justify-content-end">
                                        <Button
                                            className="p-button-filled min-w-full"
                                            label={t("label.send_invitation")}
                                            loading={makingMinorChanges === "sending_invitation"}
                                            onClick={() => {
                                                sendInvitation();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="col-12">
                                <div className="flex justify-content-between flex-wrap">
                                    <div className="flex align-items-center justify-content-center">
                                        <div className="flex flex-column">
                                            <div className="flex align-items-center justify-content-left">
                                                <label className="mousee-text font-x-small font-weight-semibold block">{t("label.status")}</label>
                                            </div>
                                            <div className="flex align-items-center justify-content-left">
                                                <label className="mousee-text-small font-x-small font-weight-regular block">{t("label.disabling_this_will_cause_the_user_to_lose_their_access")}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center">
                                        <InputSwitch checked={formik.values?.status === "active"} onChange={(e) => updateUserField("status", formik.values?.status === "active" ? "inactive" : "active")} />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="col-12 pt-5">
                            <div className="flex justify-content-center flex-wrap">
                                <div className="flex align-items-center justify-content-center">
                                    <Button className="p-button-outlined p-button-danger" label={t("label.delete_user")} loading={makingMinorChanges === "removing_user"} onClick={() => deleteUser()} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {socialLinksContent()}
                </div>
                <div className="col-12 xl:col-7">
                    <div className="grid card xl:ml-1">
                        <div className="col-12">
                            <label className="mousee-text font-medium font-weight-bold">{t("label.general_information")}</label>
                        </div>
                        <div className="col-12 md:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.name")}*</label>
                            <InputText
                                id="name"
                                name="name"
                                placeholder={t("label.name")}
                                value={formik.values.name}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("name") })}
                                onChange={(e) => {
                                    updateUserField("name", e.target.value);
                                }}
                            />
                            {getFormErrorMessage("name")}
                        </div>

                        <div className="col-12 md:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.surnames")}*</label>
                            <InputText
                                id="surnames"
                                name="surnames"
                                placeholder={t("label.surnames")}
                                value={formik.values.surnames}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("surnames") })}
                                onChange={(e) => {
                                    updateUserField("surnames", e.target.value);
                                }}
                            />
                            {getFormErrorMessage("surnames")}
                        </div>

                        <div className="col-12 md:col-12">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.email")}*</label>
                            <InputText
                                id="email"
                                name="email"
                                disabled
                                placeholder={t("label.email")}
                                value={formik.values.email}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("email") })}
                                onChange={(e) => {
                                    updateUserField("email", e.target.value);
                                }}
                            />
                            {getFormErrorMessage("email")}
                        </div>

                        <div className="col-12">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.country_or_region")}</label>
                            <Dropdown
                                dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                                value={getCountryByCode(formik?.values?.address?.country, t)}
                                onChange={(e) => updateUserField("address.country", e?.value?.isoCode)}
                                valueTemplate={selectedCountryTemplate}
                                itemTemplate={countryOptionTemplate}
                                filter
                                optionLabel="name"
                                showClear
                                placeholder={t("label.country_or_region")}
                                options={getCountriesTranslated(t)}
                                className="w-full"
                                emptyMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                                emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                            />
                        </div>

                        <div className="col-12 lg:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.address_line1")}</label>
                            <InputText
                                id="address.line1"
                                name="address.line1"
                                placeholder={t("label.address.address_line1_placeholder")}
                                value={formik?.values?.address?.line1}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.line1") })}
                                onChange={(e) => {
                                    updateUserField("address.line1", e.target.value);
                                }}
                            />
                        </div>

                        <div className="col-12 lg:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.address_line2")}</label>
                            <InputText
                                id="address.line2"
                                name="address.line2"
                                placeholder={t("label.address.address_line2_placeholder")}
                                value={formik?.values?.address?.line2}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.line2") })}
                                onChange={(e) => {
                                    updateUserField("address.line2", e.target.value);
                                }}
                            />
                        </div>

                        <div className="col-12 md:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.city")}</label>
                            <InputText
                                id="address.city"
                                name="address.city"
                                placeholder={t("label.address.city_placeholder")}
                                value={formik?.values?.address?.city}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.city") })}
                                onChange={(e) => {
                                    updateUserField("address.city", e.target.value);
                                }}
                            />
                        </div>

                        <div className="col-12 md:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.state")}</label>
                            <InputText
                                id="address.state"
                                name="address.state"
                                placeholder={t("label.address.state_placeholder")}
                                value={formik?.values?.address?.state}
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.state") })}
                                onChange={(e) => {
                                    updateUserField("address.state", e.target.value);
                                }}
                            />
                        </div>

                        <div className="col-12 md:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.address.postal_code")}</label>
                            <InputText
                                keyfilter="int"
                                id="address.postal_code"
                                name="address.postal_code"
                                placeholder={t("label.address.postal_code_placeholder")}
                                value={formik?.values?.address?.postal_code}
                                inputClassName="w-full"
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("address.postal_code") })}
                                onChange={(e) => {
                                    updateUserField("address.postal_code", e.target.value);
                                }}
                            />
                        </div>

                        <div className="col-12 md:col-6">
                            <label className="mousee-text font-x-small font-weight-semibold block pl-2">{t("label.phone_number")}</label>
                            <InputText
                                keyfilter="int"
                                id="phone_number"
                                name="phone_number"
                                placeholder={t("label.phone_number")}
                                value={formik?.values?.phone}
                                inputClassName="w-full"
                                className={classNames("block w-full", { "p-invalid": isFormFieldValid("phone") })}
                                onChange={(e) => {
                                    updateUserField("phone", e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const salaryDetailsContent = () => {
        return (
            <div className="grid">
                <div className="col-12 card md:col-12 lg:col-3">
                    <label className="block pl-2 mousee-text font-x-small font-weight-semibold pl-2">{t("label.hour_cost")}*</label>
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi">
                                <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(tenantInfo?.currency)}</label>
                            </i>
                        </span>
                        <InputNumber
                            value={formik.values.hour_cost}
                            min={0}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            locale={i18n.language.split("-")[0]}
                            onValueChange={(e) => updateUserField("hour_cost", e.value)}
                            className={classNames("w-full", { "p-invalid": isFormFieldValid("hour_cost") })}
                        />
                    </div>

                    <small className="pl-2 mousee-text-small font-weight-regular">{t("label.enter_the_cost_per_hour")}</small>
                    <br />
                    {getFormErrorMessage("hour_cost")}
                </div>
            </div>
        );
    };

    const assignSkill = (skill) => {
        if (skill?.id && originalUser?.id) {
            setAssigningProfiles([...assigningProfiles, skill?.id]);
            const assignmentParams = {
                add: [`${skill?.visibility}#${skill?.id}`],
            };

            let calls = [];

            calls.push(profileService.asignUser(assignmentParams, originalUser?.id));
            calls.push(userService.asignProfiles(assignmentParams, originalUser?.id));

            Promise.all(calls).then(() => {
                let userProfilesAux = [];
                if (userProfiles?.length > 0) {
                    userProfilesAux = [...userProfiles];
                }
                userProfilesAux?.unshift(skill);
                setUserProfiles(userProfilesAux);
                const loadingProfiles = assigningProfiles?.filter((item) => item !== skill?.id);
                setAssigningProfiles(loadingProfiles);
            });
        }
    };

    const unassignSkill = (skill) => {
        if (skill?.id && originalUser?.id) {
            confirmDialog({
                message: <span>{t("label.are_you_sure_you_want_to_remove_the_assignment_of_the_skill_skill_name", { skill_name: skill?.name })}</span>,
                header: t("label.remove_assignment"),
                draggable: false,
                icon: "pi pi-info-circle",
                acceptClassName: "p-button-danger",
                acceptLabel: t("label.yes"),
                rejectLabel: t("label.cancel"),
                rejectClassName: "p-button-outlined p-button-plain",
                accept: () => {
                    setUnassigningProfiles([...unassigningProfiles, skill?.id]);
                    const assignmentParams = {
                        remove: [`${skill?.visibility}#${skill?.id}`],
                    };

                    let calls = [];

                    calls.push(profileService.asignUser(assignmentParams, originalUser?.id));
                    calls.push(userService.asignProfiles(assignmentParams, originalUser?.id));

                    Promise.all(calls).then(() => {
                        let userProfilesAux = [];
                        if (userProfiles?.length > 0) {
                            userProfilesAux = [...userProfiles];
                        }
                        userProfilesAux = userProfilesAux?.filter((item) => item?.id !== skill?.id);
                        setUserProfiles(userProfilesAux);
                        const loadingProfiles = unassigningProfiles?.filter((item) => item !== skill?.id);
                        setUnassigningProfiles(loadingProfiles);
                    });
                },
                contentClassName: "pt-3",
            });
        }
    };

    const skillsContent = (userProfilesParam) => {
        return (
            <div className="grid card">
                <div className="col-12">
                    <label className="mousee-text font-medium font-weight-bold">{t("label.skills")}</label>
                </div>
                <div className="col-12 pb-6">
                    <Dropdown
                        dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                        id="search-user-profiles-dowpdown"
                        name="search-user-profiles-dowpdown"
                        dataKey="id"
                        // value={formik.values.profile}
                        options={profiles}
                        optionLabel="name"
                        // onChange={(e) => filterByParams(e, "profile")}
                        placeholder={t("label.select_skill_dropdown")}
                        filter
                        className="min-w-full"
                        panelClassName="custom-dropdown-without-hover"
                        itemTemplate={(option) => {
                            if (option) {
                                const flag = containsProfile(userProfiles, option?.id);
                                return (
                                    <div className="grid">
                                        <div className="col-8 flex flex-wrap align-items-center justify-content-start">
                                            <label className="mousee-text font-x-small font-weight-regular vertical-align-middle cursor-pointer">{option?.name}</label>
                                        </div>
                                        <div className="col-4 text-right">
                                            <Button
                                                className="p-button-filled"
                                                label={t(flag ? "label.skill_assigned" : "label.assign_skill")}
                                                disabled={flag}
                                                loading={assigningProfiles?.includes(option?.id)}
                                                onClick={(e) => {
                                                    e?.stopPropagation();
                                                    assignSkill(option);
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            }
                        }}
                        onShow={() => {
                            if (!profiles || profiles?.length === 0) {
                                setLoadingProfiles(true);

                                profileService
                                    .queryProfiles({ limit: profilesRowsNumber })
                                    .then((data) => {
                                        setQueryProfilesResponse(data);
                                        setProfiles(data?.data);
                                        setLoadingProfiles(false);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                    });
                            }
                        }}
                        emptyMessage={
                            loadingProfiles ? (
                                <div className="flex align-items-center">
                                    <i className="pi pi-spin pi-spinner" style={{ fontSize: "1rem" }}></i>
                                    <label className="mousee-text font-x-small font-weight-regular ml-2">{t("label.loading")}</label>
                                </div>
                            ) : (
                                <label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>
                            )
                        }
                        emptyFilterMessage={<label className="mousee-text font-x-small font-weight-regular">{t("label.no_items_to_display")}</label>}
                        onScrollCapture={(e) => handleProfilesOnScroll(e, profileService, profilesRowsNumber, queryProfilesResponse, setQueryProfilesResponse, profiles, setProfiles, profileFilterName, setProfileFilterName, "private")}
                        filterTemplate={(options) => (
                            <div className="flex flex-column min-w-full">
                                <div className="flex align-items-center justify-content-center">
                                    <span className="p-input-icon-right min-w-full">
                                        <i className="pi pi-search" />
                                        <InputText
                                            autoFocus
                                            className="min-w-full"
                                            value={profileFilterName}
                                            ref={null}
                                            onChange={(e) => filterProfiles(e, options, setProfileFilterName, queryProfilesResponse, setQueryProfilesResponse, profileService, profilesRowsNumber, profiles, setProfiles, "private")}
                                        />
                                    </span>
                                </div>
                            </div>
                        )}
                    />
                </div>

                {userProfilesParam?.length > 0 ? (
                    userProfilesParam?.map((item) => {
                        return (
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="flex justify-content-between flex-wrap">
                                            <div className="flex align-items-center justify-content-center">
                                                <div className="grid">
                                                    <div className="col-12 pb-0">
                                                        <label className="mousee-text font-x-small font-weight-semibold">{item?.name}</label>
                                                    </div>
                                                    <div className="col-12 pt-0">
                                                        <label className="mousee-text font-x-small font-weight-regular">{FormatCurrency(item.hour_cost, 2, tenantInfo?.currency, i18n.language)}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex align-items-center justify-content-center">
                                                <Button icon={<Icon icon="ph:trash-light" className="" style={{ fontSize: "20px" }} />} className="p-button-text p-button-secondary" loading={unassigningProfiles?.includes(item?.id)} onClick={() => unassignSkill(item)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 pt-0">
                                        <Divider style={{ borderColor: "var(--surface-300)" }} />
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="col-12">
                        <label className="mousee-text-small font-x-small font-weight-regular font-italic">{t("label.there_are_no_profiles_assigned_to_this_user")}</label>
                    </div>
                )}
            </div>
        );
    };

    const saveAndLeave = () => {
        formik.handleSubmit();
        confirmNavigation();
    };

    const getContent = (indexParam) => {
        if (indexParam === 0) {
            return userInfoContent();
        } else if (indexParam === 1) {
            return <UserTrack tenantInfo={tenantInfo} currentUser={formik.values} workingHours={workingHours} setWorkingHours={setWorkingHours} queryWorkingHoursResponse={queryWorkingHoursResponse} setQueryWorkingHoursResponse={setQueryWorkingHoursResponse} />;
        } else if (indexParam === 2) {
            return (
                <UserAbsences
                    currentUser={formik.values}
                    tenantInfo={tenantInfo}
                    hours={absenceHours}
                    setHours={setAbsenceHours}
                    queryHoursResponse={queryAbsenceHoursResponse}
                    setQueryHoursResponse={setQueryAbsenceHoursResponse}
                    absences={absences}
                    setAbsences={setAbsences}
                    queryAbsencesResponse={queryAbsencesResponse}
                    setQueryAbsencesResponse={setQueryAbsencesResponse}
                    updateUserField={updateUserField}
                />
            );
        } else if (indexParam === 3) {
            return salaryDetailsContent();
        } /*else if (indexParam === 4) {
            return socialLinksContent();
        } */else if (indexParam === 4) {
            return skillsContent(userProfiles);
        } else if (indexParam === 5) {
            return <UserSecurity currentUser={originalUser} userRoles={userRoles} setUserRoles={setUserRoles} />;
        } /* else if (indexParam === 6) {
            return <ChangeUserPassword currentUser={originalUser} />;
        }*/
    };

    const sendInvitation = () => {
        setMakingMinorChanges("sending_invitation");

        userService.updateUser({}, { update_params: { status: "active" } }, originalUser?.id).then((data) => {
            if (data) {
                formik.setValues(data);
                setOriginalUser(JSON.parse(JSON.stringify(data)));
                setMakingMinorChanges(undefined);
            }
        });
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    if (loading) return <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}></BlockUI>;

    return checkComponentPermissions(
        <div className="grid page-layout">
            <ConfirmDialog />
            {showPrompt ? (
                <ConfirmDialog
                    visible={showPrompt}
                    onHide={cancelNavigation}
                    message={t("message.if_you_leave_this_page_the_changes_you_have_made_will_be_lost")}
                    header={t("label.save_changes_question")}
                    contentClassName="pt-5"
                    acceptLabel={t("label.save_and_leave")}
                    rejectLabel={t("label.leave_without_save")}
                    icon="pi pi-exclamation-triangle"
                    accept={saveAndLeave}
                    reject={confirmNavigation}
                    id="confirmDialog"
                />
            ) : null}

            <Toast ref={toast} />

            {/* <div className="xl:col-offset-1"></div> */}
            <div className="col-12 xl:col-12">
                <div className="grid">
                    <div className="col-12 p-0">
                        <div className="flex  justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <div className="grid p-0">
                                    <div className="col-12 p-0">
                                        <BreadCrumb
                                            home={{
                                                icon: <label className="mousee-text font-x-small font-weight-regular cursor-pointer">{capitalizeWord(t("label.users"))}</label>,
                                                command: () => {
                                                    navigate("/people");
                                                },
                                            }}
                                            separatorIcon={<div className="pl-2 pr-2">/</div>}
                                            model={[{ label: t("label.user_details"), disabled: true }]}
                                        />
                                    </div>
                                    <div className="col-12 px-0">
                                        <label className="mousee-text font-large font-weight-regular">{`${originalUser?.name ? originalUser?.name : ""} ${originalUser?.surnames ? originalUser?.surnames : ""}`.trim()}</label>
                                    </div>
                                </div>
                            </div>

                            {checkComponentPermissions(
                                <div className="flex align-items-center justify-content-center">
                                    <Button form="update-process-form" type="submit" label={t("label.save_changes")} disabled={!changesDetected} className="p-button" onClick={formik.handleSubmit} />
                                </div>,
                                ["edit_users"],
                                null,
                                null
                            )}
                        </div>
                    </div>

                    <div className="col-12">
                        <TabMenu model={getTabItems()} activeIndex={activeIndex} className="custom-tabs" />
                    </div>

                    <div className="col-12 pt-4">{getContent(activeIndex)}</div>
                </div>
            </div>
            {/* <div className="xl:col-offset-1"></div> */}
        </div>,
        ["view_users"],
        getAuthData()?.user_id === id ? ["employee"] : null,
        <Forbidden />
    );
};
export default UserOverview;
