/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { Icon } from "@iconify/react";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import academyImg from "../../assets/img/academy.svg";
import { checkComponentPermissions } from "../../data/Permissions";
import UserService from "../../services/UserService/UserService";
import { getAuthData, getUserFullName } from "../../Utils";

const Dashboard = () => {
    const { t, i18n } = useTranslation();
    const { user, signOut } = useAuthenticator((context) => [context.user]);

    const userService = new UserService();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [currentUser, setCurrentUser] = useState();

    useEffect(() => {}, []);

    useEffect(() => {
        console.log(user)
        console.log(getAuthData())
        if (getAuthData()?.is_tenant === "false" && user) {
            setLoading(true);
            userService.getUser({}, getAuthData()?.user_id).then((data) => {
                setCurrentUser(data);
                setLoading(false);
            });
        }
    }, []);

    const quickGuideCard = (icon, title, content, buttonLabel, buttonLink, classnames) => {
        return (
            <div className={`card min-h-full ${classnames}`}>
                <div className="grid">
                    <div className="col-12">
                        <div class="flex flex-row flex-wrap">
                            <div class="flex align-items-center justify-content-center">
                                <Icon icon={icon} color="inherit" className="mousee-text font-large font-weight-bold" />
                            </div>
                            <div class="flex align-items-center justify-content-center pl-2">
                                <label className="mousee-text font-medium font-weight-bold">{title}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <label className="mousee-text font-small font-weight-light">{content}</label>
                    </div>
                    <div className="col-12">
                        <Button label={buttonLabel} className="p-button-filled" onClick={() => (buttonLink ? window.open(buttonLink, "_blank", "noopener,noreferrer") : {})} />
                    </div>
                </div>
            </div>
        );
    };

    const quickVideoCard = (buttonLink) => {
        return (
            <div className="card">
                <div className="grid">
                    <div className="col-12">
                        <div style={{ position: "relative", paddingBottom: "75%", height: "0" }} /**style="position: relative; padding-bottom: 75%; height: 0;" */>
                            <iframe
                                src={buttonLink}
                                frameborder="0"
                                webkitallowfullscreen
                                mozallowfullscreen
                                allowfullscreen
                                style={{ position: "absolute", top: "0", left: "", width: "100%", height: "100%", borderRadius: "var(--border-radius)" }}
                                // style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}>
            <div className="page-layout">
                <div className="grid">
                    <div className="col-12">
                        <div className="flex justify-content-between flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <div className="grid p-0">
                                    <div className="col-12 p-0">
                                        <label className="mousee-text font-large font-weight-regular">{t("label.dashboard")}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <div className="flex flex-row flex-wrap">
                                    {checkComponentPermissions(
                                        <div className="flex align-items-center justify-content-center">
                                            <Button
                                                icon={<Icon icon="material-symbols:rocket" className="mr-2" style={{ fontSize: "24px" }} />}
                                                label={t("label.upgrade_your_plan")}
                                                type="button"
                                                className="p-button min-w-full p-button-success"
                                                onClick={() => window.open("/organization?view=billing", "_blank", "noopener,noreferrer")}
                                            />
                                        </div>,
                                        ["edit_tenant"],
                                        null,
                                        null
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 pl-0">
                        <label className="mousee-text-small font-medium font-weight-regular">{t("label.welcome_username", { username: getUserFullName(currentUser) })}</label>
                    </div>

                    <div className="col-12 pl-0">
                        <div className="card">
                            <div className="grid">
                                <div className="col-12 xl:col-6">
                                    <div className="grid">
                                        <div className="col-12">
                                            <label className="mousee-text font-large font-weight-semibold">{t("label.start_setting_up_your_account")}</label>
                                        </div>
                                        <div className="col-12">
                                            <label className="mousee-text font-small font-weight-light">{t("label.we_have_created_an_online_course_that_will_allow_you_to_configure_your_miflow_account_from_start_to_finish_your_account")}</label>
                                        </div>
                                        <div className="col-12">
                                            <ul>
                                                <li className="mt-3">
                                                    <label className="mousee-text font-small font-weight-light">{t("label.explore_the_videos_in_order_to_get_the_most_out_of_your_learning_experience")}</label>
                                                </li>
                                                <li className="mt-3">
                                                    <label className="mousee-text font-small font-weight-light">{t("label.our_users_usually_complete_the_course_in_a_few_days_but_take_your_time_to_reflect_on_the_topics_that_you_think_are_relevant_to_your_business")}</label>
                                                </li>
                                                <li className="mt-3">
                                                    <label className="mousee-text font-small font-weight-light">{t("label.your_progress_is_automatically_saved_so_dont_worry_you_can_come_back_at_any_time_and_continue_where_you_left_off")}</label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-12">
                                            <Button label={t("label.start_the_course_now")} onClick={() => window.open("https://raul-s-site-81fc.thinkific.com/enroll/2618739?price_id=3432788", "_blank", "noopener,noreferrer")} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 xl:col-6">
                                    <img src={academyImg} alt="Academy" className="border-solid" style={{ borderRadius: "var(--border-radius)", borderColor: "var(--surface-300)", width: "100%" }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 pl-0 xl:pt-4">
                        <label className="mousee-text-small font-medium font-weight-regular">{t("label.need_some_help_name_dont_worry", { username: getUserFullName(currentUser) !== "" ? getUserFullName(currentUser) : t("label.astronaut") })}</label>
                    </div>

                    <div className="col-12 xl:col lg:col">{quickGuideCard("material-symbols-light:dictionary-sharp", t("label.quick_guides"), t("label.easy_to_follow_guides_on_the_main_features_of_miflow"), t("label.go_to_our_blog"), "https://www.miflow.io/blog/", "")}</div>
                    <div className="xl:col-offset-1"></div>
                    <div className="col-12 xl:col lg:col">{quickGuideCard("material-symbols-light:psychology-alt-sharp", t("label.need_help"), t("label.our_experts_are_always_there_to_support_you_if_you_collapse"), t("label.contact_an_expert"), undefined, "")}</div>
                    <div className="xl:col-offset-1"></div>
                    <div className="col-12 xl:col lg:col">{quickGuideCard("material-symbols-light:event-note-rounded", t("label.book_a_demo"), t("label.discover_how_our_platform_can_help_your_business"), t("label.talk_to_a_salesperson"), "https://calendar.app.google/UuAR84aZhZ7KzZ489", "")}</div>

                    <div className="col-12 pl-0 xl:pt-7">
                        <label className="mousee-text-small font-medium font-weight-regular">{t("label.contents_of_interest_to_you")}</label>
                    </div>

                    <div className="col-12 xl:col">{quickVideoCard("https://www.loom.com/embed/cd0cac0904764d5fbaa0c6b53999d977?sid=29eda098-7c94-47eb-b3a0-d7ba1569a0f2")}</div>
                    <div className="xl:col-offset-1"></div>
                    <div className="col-12 xl:col">{quickVideoCard("https://www.loom.com/embed/358c77dcfab44443b273c12a3da1ef54?sid=bc39b5c2-57b4-4b03-83d8-d5b08b9d491f")}</div>
                    <div className="xl:col-offset-1"></div>
                    <div className="col-12 xl:col">{quickVideoCard("https://www.loom.com/embed/8c3ccd8713704e0691caeaa5fc52ef51?sid=ab4176a4-a929-45ef-b382-5a2f54140ce9")}</div>
                </div>
            </div>
        </BlockUI>
    );
};
export default Dashboard;
