import { useAuthenticator } from "@aws-amplify/ui-react";
import { Buffer } from "buffer";
import { useFormik } from "formik";
import isEqual from "lodash.isequal";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { ConfirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {convertToBase64, getAuthData, getUpdateRemoveParams} from "../../../Utils";
import AvatarUpload from "../../../components/AvatarUpload/AvatarUpload";
import { getAdditionalTopBarComponentsV2 } from "../../../data/TabsData";
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt";
import UserService from "../../../services/UserService/UserService";
import { getUserLogoUrl } from "../../People/Users/Utils";

const AccountConfiguration = ({ setTopbarAdditionalComponents, setOption }) => {
    const { user } = useAuthenticator((context) => [context.user]);

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const userService = new UserService();
    const toast = useRef();

    const { t, i18n } = useTranslation();

    const [changesDetected, setChangesDetected] = useState(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(changesDetected);

    const [currentFile, setCurrentFile] = useState();
    const fileUploadRef = useRef(null);

    const [originalUser, setOriginalUser] = useState();

    useEffect(() => {
        setTopbarAdditionalComponents(getAdditionalTopBarComponentsV2("account_configuration", navigate, ""));
    }, []);

    useEffect(() => {
        setOption("");
    }, []);

    useEffect(() => {
        setLoading(true);
        const userSub =  getAuthData()?.user_id

        userService
            .getUser({}, userSub)
            .then((data) => {
                formik.setValues(data);
                setOriginalUser(JSON.parse(JSON.stringify(data)));
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const formik = useFormik({
        initialValues: {
            id: "",
            name: "",
            surnames: "",
            email: "",
            phone: "",
            birthday: "",
            type: "",
            status: "",
            links: [],
            hour_cost: 0,
            avatar: "",
            external: undefined,

            image: undefined,
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = t("label.field_required");
            }

            if (!data.surnames) {
                errors.surnames = t("label.field_required");
            }

            if (!data.email) {
                errors.email = t("label.field_required");
            }

            if (!data.type) {
                errors.type = t("label.field_required");
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);

            const updateData = getUpdateRemoveParams(originalUser, data);

            userService
                .updateUser({}, updateData, data.id)
                .then((createdUserData) => {
                    if (originalUser && createdUserData.avatar && createdUserData.avatar !== originalUser.avatar) {
                        convertToBase64(currentFile).then((convertedFile) => {
                            fetch(createdUserData.avatar, {
                                method: "PUT",
                                body: new Buffer.from(convertedFile.replace(/^data:image\/\w+;base64,/, ""), "base64"),
                                headers: {
                                    "Content-type": currentFile.type,
                                },
                            })
                                .then((response) => {
                                    formik.setValues(createdUserData);
                                    setOriginalUser(JSON.parse(JSON.stringify(data)));
                                    setLoading(false);
                                    setChangesDetected(false);
                                })
                                .catch((error) => {
                                    console.error("Error:", error);
                                    formik.resetForm();
                                    setLoading(false);
                                });
                        });
                    } else {
                        formik.setValues(createdUserData);
                        setOriginalUser(JSON.parse(JSON.stringify(data)));
                        setLoading(false);
                        setChangesDetected(false);
                    }
                })
                .catch((e) => {
                    console.log(e);
                    setLoading(false);
                });
        },
    });

    const updateField = (fieldName, fieldValue) => {
        let newData = formik.values;
        newData[fieldName] = fieldValue;

        if (newData && originalUser && !isEqual(newData[fieldName], originalUser[fieldName])) {
            formik.setFieldValue(fieldName, fieldValue);
        }

        setChangesDetected(!isEqual(newData, originalUser));
    };

    const saveAndLeave = () => {
        formik.handleSubmit();
        confirmNavigation();
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    if (loading) return <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}></BlockUI>;

    return (
        <div className="grid page-layout">
            <Toast toast={toast} />
            <ConfirmDialog
                visible={showPrompt}
                onHide={cancelNavigation}
                message={t("message.if_you_leave_this_page_the_changes_you_have_made_will_be_lost")}
                header={t("label.save_changes_question")}
                contentClassName="pt-5"
                acceptLabel={t("label.save_and_leave")}
                rejectLabel={t("label.leave_without_save")}
                icon="pi pi-exclamation-triangle"
                accept={saveAndLeave}
                reject={confirmNavigation}
            />

            <div className="xl:col-offset-2"></div>
            <div className="col-12 xl:col-8">
                <div className="grid">
                    <div className="col-12">
                        <div className="flex justify-content-between align-content-center flex-wrap ">
                            <div className="flex align-items-center justify-content-center">
                                <label className="mousee-text font-large font-weight-semibold">{t("label.general_information")}</label>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                                <Button type="submit" disabled={!changesDetected} label={t("label.save_changes")} className="p-button" onClick={() => formik.handleSubmit()} />
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="grid">
                            <div className="col-12 md:col-12 lg:col-3">
                                <AvatarUpload
                                    inputFileRef={fileUploadRef}
                                    file={currentFile ? currentFile : formik.values.avatar}
                                    setFile={(newValue) => {
                                        setCurrentFile(newValue);
                                        updateField("avatar", getUserLogoUrl(user, formik.values.id, newValue?.name));
                                    }}
                                />
                            </div>

                            <div className="col-12 md:col-12 lg:col-9">
                                <div className="grid">
                                    <div className="col-12">
                                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                                            {t("label.name")}*
                                        </label>
                                        <InputText
                                            id="name"
                                            name="name"
                                            placeholder={t("label.name")}
                                            value={formik.values.name}
                                            className={classNames("w-full", { "p-invalid": formik.errors.name })}
                                            onChange={(e) => {
                                                updateField("name", e.target.value);
                                            }}
                                        />
                                        {getFormErrorMessage("name")}
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold pl-2">
                                            {t("label.surnames")}*
                                        </label>
                                        <InputText
                                            id="surnames"
                                            name="surnames"
                                            placeholder={t("label.surnames")}
                                            value={formik.values.surnames}
                                            className={classNames("w-full", { "p-invalid": formik.errors.surnames })}
                                            onChange={(e) => {
                                                updateField("surnames", e.target.value);
                                            }}
                                        />
                                        {getFormErrorMessage("surnames")}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="grid pt-0 md:pt-0 lg:pt-3">
                            <div className="col-12 md:col-12 lg:col-4 xl:col-5">
                                <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold block pl-2">
                                    {t("label.email")}*
                                </label>
                                <InputText
                                    id="email"
                                    name="email"
                                    disabled={true}
                                    placeholder={t("label.email")}
                                    value={formik.values.email}
                                    className={classNames("w-full", { "p-invalid": formik.errors.email })}
                                    onChange={(e) => {
                                        updateField("email", e.target.value);
                                    }}
                                />
                                {getFormErrorMessage("email")}
                            </div>

                            <div className="col-12 md:col-12 lg:col-4 xl:col-4">
                                <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold block pl-2">
                                    {t("label.phone_number")}
                                </label>
                                <InputText
                                    id="phone"
                                    name="phone"
                                    placeholder={t("label.phone_number")}
                                    value={formik.values.phone}
                                    className="w-full"
                                    onChange={(e) => {
                                        updateField("phone", e.target.value);
                                    }}
                                />
                            </div>

                            <div className="col-12 md:col-12 lg:col-4 xl:col-3">
                                <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold block pl-2">
                                    {t("users.page.birthday")}
                                </label>
                                <Calendar className="min-w-full" iconPos="left" showIcon maxDate={new Date()} value={formik.values.birthday} onChange={(e) => updateField("birthday", e.value)} locale={i18n?.language?.split("-")[0]} />
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-12">
                        <Divider className="border-300" />
                    </div> */}
                </div>
            </div>

            <div className="xl:col-offset-2"></div>
        </div>
    );
};
export default AccountConfiguration;
