import { useAuthenticator } from "@aws-amplify/ui-react";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAuthData } from "../../../../Utils";
import { getUserTypeLabel } from "../../../../data/User";
import BudgetService from "../../../../services/BudgetService/BudgetService";
import ClientService from "../../../../services/ClientService/ClientService";
import NotificationService from "../../../../services/NotificationService/NotificationService";
import UserService from "../../../../services/UserService/UserService";
import { clientIndividualItemTemplate } from "../../../People/Clients/Utils";
import { filterUsers, handleUsersOnScroll } from "../../../People/Users/Utils";
import { taskParticipantUserOptionTemplate } from "../../../Task/Utils";

const SendBudgetDialog = ({ budget, refreshBudgets, setBudget, show, setShow }) => {
    const { t, i18n } = useTranslation();
    const toast = useRef(null);
    const budgetService = new BudgetService();
    const formRef = useRef();
    const [loading, setLoading] = useState(false);
    const userService = new UserService();
    const notificationService = new NotificationService();
    const clientService = new ClientService();
    const [currentUser, setCurrentUser] = useState();

    const { user } = useAuthenticator((context) => [context.user]);

    const [userFilterName, setUserFilterName] = useState(undefined);
    const [users, setUsers] = useState([]);
    const [queryUsersResponse, setQueryUsersResponse] = useState();
    const [usersRowsNumber, setUsersRowsNumber] = useState(5);

    const [clientUserFilterName, setClientUserFilterName] = useState(undefined);
    const [clientUsers, setClientUsers] = useState([]);
    const [queryClientUsersResponse, setQueryClientUsersResponse] = useState();
    const [clientUsersRowsNumber, setClientUsersRowsNumber] = useState(5);

    const [clientData, setClientData] = useState();

    useEffect(() => {
        if (budget && budget.id) {
            setLoading(true);
            const client_id = budget.id.split("#")[0];

            let usersCalls = [];

            clientService
                .getClient(client_id)
                .then((clientData) => {
                    setClientData(clientData);
                    if (clientData?.type === "company") {
                        usersCalls.push(
                            userService
                                .queryUsers({ client_id: client_id, limit: clientUsersRowsNumber })
                                .then((data) => {
                                    setQueryClientUsersResponse(data);
                                    setClientUsers(data?.data);
                                })
                                .catch((error) => {
                                    console.log(error);
                                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                })
                        );
                    } else {
                        setClientUsers([clientData]);
                    }

                    usersCalls.push(
                        userService
                            .queryUsers({ limit: usersRowsNumber })
                            .then((data) => {
                                setQueryUsersResponse(data);
                                setUsers(data?.data);
                            })
                            .catch((error) => {
                                console.log(error);
                                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                            })
                    );

                    Promise.all(usersCalls).then(() => {
                        setLoading(false);
                    });
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    }, []);

    useEffect(() => {
        if (getAuthData()?.is_tenant === "false" && user && user?.attributes && user?.attributes?.sub) {
            userService.getUser({},  getAuthData()?.user_id).then((data) => {
                setCurrentUser(data);
            });
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            users: [],
            client_users: [],
        },
        validate: (data) => {
            let errors = {};

            let checkOne = false;
            let checkTwo = false;

            if (!data.users || (data.users && data.users.length === 0)) {
                checkOne = true;
            }

            if (!data.client_users || (data.client_users && data.client_users.length === 0)) {
                checkTwo = true;
            }

            if (checkOne && checkTwo) {
                errors.common = t("message.you_must_indicate_at_least_one_user");
            }

            return errors;
        },
        onSubmit: (data) => {
            if (budget && budget.id) {
                setLoading(true);

                const clientId = budget.id.split("#")[0];
                const budgetId = budget.id.split("#")[1];

                const updateParams = {
                    update_params: {
                        status: "sent",
                    },
                };

                budgetService
                    .updateBudget(updateParams, clientId, budgetId)
                    .then((data) => {
                        if (refreshBudgets) {
                            refreshBudgets(data);
                        } else if (setBudget) {
                            setBudget(data);
                        }

                        let createNotificationsCalls = [];

                        const newNotification = {
                            author: `${currentUser?.name} ${currentUser?.surnames}`.trim(),
                            avatar: currentUser?.avatar,
                            readed: "false",
                            type: "budget",
                            url: `/budgets?client_id=${clientId}&budget_id=${budgetId}`,
                            key: budget.id,
                            data: {
                                title: t("label.new_budget"),
                                content: t("message.you_have_received_a_quote_for_the_x_project", { project_name: data?.concept }),
                                project_name: data.concept,
                            },
                        };

                        // Send internal users notifications
                        formik.values.users.forEach((user) => {
                            const userNotification = {
                                ...newNotification,
                                data: {
                                    ...newNotification.data,
                                    email: user?.email,
                                },
                            };
                            createNotificationsCalls.push(notificationService.createNotification(userNotification, user?.id));
                        });

                        // Send client users notifications
                        formik.values.client_users.forEach((clientUser) => {
                            const userNotification = {
                                ...newNotification,
                                data: {
                                    ...newNotification.data,
                                    email: clientUser?.email,
                                },
                            };
                            createNotificationsCalls.push(notificationService.createNotification(userNotification, clientUser?.id));
                        });

                        Promise.all(createNotificationsCalls).then((creationResponses) => {
                            setLoading(false);
                            setShow(false);
                        });
                    })
                    .catch((error) => {
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                        setLoading(false);
                        setShow(false);
                    });
            }

            if (refreshBudgets && setBudget) {
                setBudget(null);
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const saveChanges = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
            setShow(false);
        }
    };

    return (
        <Dialog
            visible={show}
            onHide={() => setShow(false)}
            style={{ minWidth: "35vw", maxWidth: "35vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            draggable={false}
            header={
                <div>
                    <span className="mousee-text font-medium font-weight-bold">{t("budgets.page.send_budget")}</span>
                </div>
            }
            footer={
                <div>
                    <Button form="new-profile-form" className="p-button" type="submit" label={t("label.send")} autoFocus onClick={saveChanges} loading={loading} />
                </div>
            }
        >
            <Toast ref={toast} />
            <div className="pt-3">
                <span className="mousee-text font-small font-weight-semibold">{t("budgets.page.select_the_users_to_whom_you_want_to_send_the_budget")}</span>

                <div className="pt-5">
                    <form id="new-profile-form" onSubmit={formik.handleSubmit} innerRef={formRef} className="p-fluid">
                        <div className="grid">
                            <div className="col-12">
                                <div className="flex align-items-left justify-content-left">
                                    <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold block pl-2">
                                        {t("label.my_clients")}
                                    </label>
                                </div>
                                <div className="flex align-items-center justify-content-center pt-2">
                                    <MultiSelect
                                        value={formik.values.client_users}
                                        onChange={(e) => formik.setFieldValue("client_users", e.value)}
                                        options={clientUsers}
                                        optionLabel={(item) => `${item.name}${item.surnames ? ` ${item.surnames}` : ""}`}
                                        display="chip"
                                        // filter
                                        placeholder={t("label.dropdown_select")}
                                        maxSelectedLabels={3}
                                        className={classNames("w-full", { "p-invalid": formik.errors.common })}
                                        filter
                                        onScrollCapture={(e) => handleUsersOnScroll(e, userService, clientUsersRowsNumber, queryClientUsersResponse, setQueryClientUsersResponse, clientUsers, setClientUsers, clientUserFilterName, setClientUserFilterName, budget.id.split("#")[0])}
                                        filterTemplate={(options) => (
                                            <span className="p-input-icon-right w-full pl-3">
                                                <i className="pi pi-search" />
                                                <InputText
                                                    autoFocus
                                                    className="w-full"
                                                    value={userFilterName}
                                                    ref={null}
                                                    onChange={(e) => filterUsers(e, options, setClientUserFilterName, queryClientUsersResponse, setQueryClientUsersResponse, userService, clientUserFilterName, clientUsers, setClientUsers, budget.id.split("#")[0])}
                                                />
                                            </span>
                                        )}
                                        itemTemplate={clientData?.type === "individual" ? clientIndividualItemTemplate(clientData, "medium") : taskParticipantUserOptionTemplate}
                                    />
                                </div>
                                {getFormErrorMessage("common")}
                            </div>
                            {/* {clientData?.type === "company" ? (

                            ) : (
                                <div className="col-12">
                                    <div className="flex align-items-left justify-content-left">
                                        <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold block pl-2">
                                            {t("label.client")}
                                        </label>
                                    </div>
                                    <div className="flex align-items-center justify-content-left pt-2">
                                        {clientIndividualItemTemplate(clientData, "large")}
                                        {!clientData?.email ? <small className="pl-2 mousee-text-small font-weight-regular">{t("label.no_email_provided")}</small> : null}
                                    </div>
                                </div>
                            )} */}
                            <div className="col-12 pt-4">
                                <div className="flex align-items-left justify-content-left">
                                    <label htmlFor="form-name" className="mousee-text font-x-small font-weight-semibold block pl-2">
                                        {t("label.my_team")}
                                    </label>
                                </div>

                                <div className="flex align-items-center justify-content-center pt-2">
                                    <MultiSelect
                                        value={formik.values.users}
                                        onChange={(e) => formik.setFieldValue("users", e.value)}
                                        options={users}
                                        optionLabel={(item) => `${item.name}${item.surnames ? ` ${item.surnames}` : ""} (${t(getUserTypeLabel(item.type))})`}
                                        display="chip"
                                        placeholder={t("label.dropdown_select")}
                                        maxSelectedLabels={3}
                                        className={classNames("w-full", { "p-invalid": formik.errors.common })}
                                        filter
                                        onScrollCapture={(e) => handleUsersOnScroll(e, userService, usersRowsNumber, queryUsersResponse, setQueryUsersResponse, users, setUsers, userFilterName, setUserFilterName, undefined)}
                                        filterTemplate={(options) => (
                                            <span className="p-input-icon-right w-full pl-3">
                                                <i className="pi pi-search" />
                                                <InputText autoFocus className="w-full" value={userFilterName} ref={null} onChange={(e) => filterUsers(e, options, setUserFilterName, queryUsersResponse, setQueryUsersResponse, userService, usersRowsNumber, users, setUsers, undefined)} />
                                            </span>
                                        )}
                                        itemTemplate={taskParticipantUserOptionTemplate}
                                    />
                                </div>
                                {getFormErrorMessage("common")}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    );
};
export default SendBudgetDialog;
